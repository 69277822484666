import * as B from '@blueprintjs/core';
import classnames from 'classnames';
import React from 'react';

import * as cs from './Dashboard.styl';

/**
 * A generic widget for the dashboard. Controls size and layout
 * of content on the dashboard.
 */
export const DashboardWidget: React.FC<{
  title: string;
  //   description: string; // TODO - add description
  size: 'small' | 'large';
  children: React.ReactNode;
}> = ({title, size, children}) => {
  return (
    <div
      className={classnames(
        cs.dashboardWidget,
        size === 'large' ? cs.dashboardWidgetLarge : cs.dashboardWidgetSmall
      )}
    >
      <div className={cs.dashboardWidgetHeader}>
        <h3>{title}</h3>
        <B.AnchorButton icon="more" minimal small /> {/* TODO - add actions here! */}
      </div>
      {children}
    </div>
  );
};
